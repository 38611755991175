<template>
    <modal ref="modalUnidadesInsuficientes" titulo="Productos insuficientes" no-cancelar adicional="Cerrar" @adicional="toggle()">
        <div v-if="productosAgotados != null" class="row mx-0 j-center mb-3">
            <div class="col-auto text-center text-general f-15">
                Algunos productos no tiene unidades <br /> suficientes o se han agotado
            </div>
        </div>
        <div class="row mx-0 j-center">
            <div v-for="data in productosAgotados" :key="data.id " class="col-10">
                <card-producto-carrito :producto="data.producto" :sin-stock="true" @eliminarPedido="actualizarInfo" @carritoActualizado="actualizarInfo" />
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    components: {
        cardProductoCarrito: () => import('../components/cardProductoCarrito')
    },
    props: {
        productosAgotados: {
            type: Array,
            default: null
        },
    },
    data(){
        return {

        }
    },
    methods: {
        toggle(){
            this.$refs.modalUnidadesInsuficientes.toggle();
        },
        actualizarInfo(){
            this.$emit('eliminarPedido')
        }
    }
}
</script>
